import React, { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { PdfComponent } from '../pages/reports';

export default function PdfGenerator({ documentTitle, data }: { documentTitle: string; data: any }) {
  const [pdfBlob, setpdfBlob]: any = useState(null);
  const [status, setStatus]: any = useState('loading');

  useEffect(() => {
    try {
      setStatus('loading');
      setpdfBlob(null);
      const worker = new Worker(new URL('../workers/pdf.worker.ts', import.meta.url));
      worker.addEventListener('message', (event) => {
        if (event.data instanceof Blob) {
          setpdfBlob(event.data);
          setStatus('success');
          worker.terminate();
        }
      });
      worker.addEventListener('error', (event) => {
        setStatus('error');
        worker.terminate();
      });
      worker.addEventListener('messageerror', (event) => {
        setStatus('error');
        worker.terminate();
      });
      worker.postMessage({ title: documentTitle, data: data }); // Pass the function name and arguments
    } catch (err) {
      console.log(err);
    }
  }, [documentTitle, data]);
  return (
    <>
      {status === 'success' && (
        <a href={URL.createObjectURL(pdfBlob)} download={`${documentTitle}_${new Date().toLocaleDateString()}.pdf`}>
          Download PDF
        </a>
      )}
      {status === 'loading' && <p>{`PDF is being generated...(It may take a long time for larger data)`}</p>}
      {status === 'error' && <p>Error while generating PDF</p>}
    </>
  );
}
