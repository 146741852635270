import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from 'react-csv';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';

const ReceiptPOCReport = () => {
  const [receiptNo, setReceiptNo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [from, setFrom] = useState(dateFormat(new Date()));
  const [to, setTo] = useState(dateFormat(new Date()));
  const [pdfData, setPdfData] = useState({
    from: '',
    to: '',
    tableTitles: [],
    tableContents: [],
    mmfTotal: {},
    cottonTotal: {},
  });
  const filterColumns: Array<String> = [
    'Bale_count',
    'Customer_Name',
    'Customer_Sort',
    'Fents_Count',
    'Fents_Mts',
    'LMts',
    'Others_Count',
    'Others_Mts',
    'Rags_Count',
    'Rags_Mts',
    'Seconds_Count',
    'Seconds_Mts',
    'Short_Length_Count',
    'Short_Length_Mts',
    'Sound_Count',
    'Sound_Mts',
    'cotton',
    'id',
    'id_lot_num',
    'mmf',
  ];
  const getLocaleNumber = (num) => {
    let currNum = +num;
    return currNum.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const columns = [
    {
      name: 'Id',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Id Lot Num',
      selector: (row) => `${row.id_lot_num}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Name',
      selector: (row) => `${row.Customer_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Sort',
      selector: (row) => `${row.Customer_Sort}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bale Count',
      selector: (row) => `${row.Bale_count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'L.Mtrs',
      selector: (row) => `${row.LMts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Sound Count',
      selector: (row) => `${row.Sound_Count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Sound Mts',
      selector: (row) => `${row.Sound_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Short Length Count',
      selector: (row) => `${row.Short_Length_Count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Short Length Mts',
      selector: (row) => `${row.Short_Length_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Second Count',
      selector: (row) => `${row.Seconds_Count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Second Mts',
      selector: (row) => `${row.Seconds_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Fents Count',
      selector: (row) => `${row.Fents_Count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Fents Mts',
      selector: (row) => `${row.Fents_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Rag Count',
      selector: (row) => `${row.Rags_Count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Rag Mts',
      selector: (row) => `${row.Rags_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Other Count',
      selector: (row) => `${row.Others_Count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Other Mts',
      selector: (row) => `${row.Others_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const pdfTableTitles = [
    'Lt No',
    'Cust Name',
    'Cust Sort',
    'Bale Count',
    'L.Mtrs',
    'Sou Count',
    'Sou Mts',
    'SL Count',
    'SL Mts',
    'Sec Count',
    'Sec Mts',
    'Fen Count',
    'Fen Mts',
    'Rag Count',
    'Rag Mts',
    'Oth Count',
    'Oth Mts',
  ];
  const getApi = async () => {
    showLoader();
    let res = await axios.get(`${baseUri()}grey-receipt-advice/lotwise-packing-report?from=${from}&to=${to}`);
    res = (await res.data.data?.result) || [];
    const pdfTableContents = [];
    const cottonContents = [];
    const cottonTotal = {
      'Bale Count': 0,
      'L.Mtrs': 0,
      'Sou Count': 0,
      'Sou Mts': 0,
      'SL Count': 0,
      'SL Mts': 0,
      'Sec Count': 0,
      'Sec Mts': 0,
      'Fen Count': 0,
      'Fen Mts': 0,
      'Rag Count': 0,
      'Rag Mts': 0,
      'Oth Count': 0,
      'Oth Mts': 0,
    };
    const mmfTotal = {
      'Bale Count': 0,
      'L.Mtrs': 0,
      'Sou Count': 0,
      'Sou Mts': 0,
      'SL Count': 0,
      'SL Mts': 0,
      'Sec Count': 0,
      'Sec Mts': 0,
      'Fen Count': 0,
      'Fen Mts': 0,
      'Rag Count': 0,
      'Rag Mts': 0,
      'Oth Count': 0,
      'Oth Mts': 0,
    };
    const mmfContents = [];
    res.forEach((row) => {
      if (row.cotton) {
        cottonContents.push([
          row.id_lot_num,
          row.Customer_Name,
          row.Customer_Sort,
          row.Bale_count,
          getLocaleNumber(row.LMts),
          row.Sound_Count,
          getLocaleNumber(row.Sound_Mts),
          row.Short_Length_Count,
          getLocaleNumber(row.Short_Length_Mts),
          row.Seconds_Count,
          getLocaleNumber(row.Seconds_Mts),
          row.Fents_Count,
          getLocaleNumber(row.Fents_Mts),
          row.Rags_Count,
          getLocaleNumber(row.Rags_Mts),
          row.Others_Count,
          getLocaleNumber(row.Others_Mts),
        ]);
        cottonTotal['Bale Count'] += +row.Bale_count;
        cottonTotal['L.Mtrs'] += +row.LMts;
        cottonTotal['Sou Count'] += +row.Sound_Count;
        cottonTotal['Sou Mts'] += +row.Sound_Mts;
        cottonTotal['SL Count'] += +row.Short_Length_Count;
        cottonTotal['SL Mts'] += +row.Short_Length_Mts;
        cottonTotal['Sec Count'] += +row.Seconds_Count;
        cottonTotal['Sec Mts'] += +row.Seconds_Mts;
        cottonTotal['Fen Count'] += +row.Fents_Count;
        cottonTotal['Fen Mts'] += +row.Fents_Mts;
        cottonTotal['Rag Count'] += +row.Rags_Count;
        cottonTotal['Rag Mts'] += +row.Rags_Mts;
        cottonTotal['Oth Count'] += +row.Others_Count;
        cottonTotal['Oth Mts'] += +row.Others_Mts;
      }
      if (row.mmf) {
        mmfContents.push([
          row.id_lot_num,
          row.Customer_Name,
          row.Customer_Sort,
          row.Bale_count,
          getLocaleNumber(row.LMts),
          row.Sound_Count,
          getLocaleNumber(row.Sound_Mts),
          row.Short_Length_Count,
          getLocaleNumber(row.Short_Length_Mts),
          row.Seconds_Count,
          getLocaleNumber(row.Seconds_Mts),
          row.Fents_Count,
          getLocaleNumber(row.Fents_Mts),
          row.Rags_Count,
          getLocaleNumber(row.Rags_Mts),
          row.Others_Count,
          getLocaleNumber(row.Others_Mts),
        ]);
        mmfTotal['Bale Count'] += +row.Bale_count;
        mmfTotal['L.Mtrs'] += +row.LMts;
        mmfTotal['Sou Count'] += +row.Sound_Count;
        mmfTotal['Sou Mts'] += +row.Sound_Mts;
        mmfTotal['SL Count'] += +row.Short_Length_Count;
        mmfTotal['SL Mts'] += +row.Short_Length_Mts;
        mmfTotal['Sec Count'] += +row.Seconds_Count;
        mmfTotal['Sec Mts'] += +row.Seconds_Mts;
        mmfTotal['Fen Count'] += +row.Fents_Count;
        mmfTotal['Fen Mts'] += +row.Fents_Mts;
        mmfTotal['Rag Count'] += +row.Rags_Count;
        mmfTotal['Rag Mts'] += +row.Rags_Mts;
        mmfTotal['Oth Count'] += +row.Others_Count;
        mmfTotal['Oth Mts'] += +row.Others_Mts;
      }
    });
    cottonContents.push([
      '',
      '',
      'Total',
      cottonTotal['Bale Count'],
      getLocaleNumber(cottonTotal['L.Mtrs']),
      cottonTotal['Sou Count'],
      getLocaleNumber(cottonTotal['Sou Mts']),
      cottonTotal['SL Count'],
      getLocaleNumber(cottonTotal['SL Mts']),
      cottonTotal['Sec Count'],
      getLocaleNumber(cottonTotal['Sec Mts']),
      cottonTotal['Fen Count'],
      getLocaleNumber(cottonTotal['Fen Mts']),
      cottonTotal['Rag Count'],
      getLocaleNumber(cottonTotal['Rag Mts']),
      cottonTotal['Oth Count'],
      getLocaleNumber(cottonTotal['Oth Mts']),
    ]);
    mmfContents.push([
      '',
      '',
      'Total',
      mmfTotal['Bale Count'],
      getLocaleNumber(mmfTotal['L.Mtrs']),
      mmfTotal['Sou Count'],
      getLocaleNumber(mmfTotal['Sou Mts']),
      mmfTotal['SL Count'],
      getLocaleNumber(mmfTotal['SL Mts']),
      mmfTotal['Sec Count'],
      getLocaleNumber(mmfTotal['Sec Mts']),
      mmfTotal['Fen Count'],
      getLocaleNumber(mmfTotal['Fen Mts']),
      mmfTotal['Rag Count'],
      getLocaleNumber(mmfTotal['Rag Mts']),
      mmfTotal['Oth Count'],
      getLocaleNumber(mmfTotal['Oth Mts']),
    ]);
    pdfTableContents.push(['Cotton']);
    pdfTableContents.push(...cottonContents);
    pdfTableContents.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
    pdfTableContents.push(['MMF']);
    pdfTableContents.push(...mmfContents);
    for (let i in cottonTotal) {
      cottonTotal[i] = getLocaleNumber(cottonTotal[i]);
    }
    for (let i in mmfTotal) {
      mmfTotal[i] = getLocaleNumber(mmfTotal[i]);
    }
    setPdfData({
      from: new Date(from).toLocaleDateString('en-IN'),
      to: new Date(to).toLocaleDateString('en-IN'),
      tableTitles: pdfTableTitles,
      tableContents: pdfTableContents,
      cottonTotal: cottonTotal,
      mmfTotal: mmfTotal,
    });
    setTableData(res);
    setItemData(res);
    hideLoader();
  };

  return (
    <>
      <SEO title="Lotwise Packing Report" />
      <Card>
        <header>Lotwise Packing Report</header>
        <CardBody>
          <Row>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                From Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="from"
                  value={from}
                  placeholder="From Date"
                  onChange={(e) => setFrom(e.target.value)}
                  className="from require"
                />
              </InputGroup>
            </Col>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                To Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="to"
                  value={to}
                  placeholder="To Date"
                  onChange={(e) => setTo(e.target.value)}
                  className="to require"
                />
              </InputGroup>
            </Col>
          </Row>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <Button
              status="Success"
              style={{
                marginRight: '10px',
              }}
              onClick={getApi}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <header>Lotwise Packing Report</header>
        <CardBody>
          {tableData.length > 0 && (
            <div>
              <CSVLink data={tableData}>Download Excel</CSVLink>{' '}
              <PdfGenerator documentTitle="lotwise-packing-report" data={pdfData} />
            </div>
          )}
          <InputGroup fullWidth size="Small">
            <input
              type="text"
              onChange={(e) => filterChange(e.target.value)}
              placeholder="Filter"
              name="tableFilter"
              value={tableFilter}
            />
          </InputGroup>
          <DataTable
            columns={columns}
            data={tableData}
            fixedHeader
            allowOverflow={false}
            overflowY={false}
            overflowX={false}
            wrap={false}
            highlightOnHover={true}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ReceiptPOCReport;
